.sectionSpace{
    margin-bottom: 100px;
}
  
.sectionSpaceSmall{
    margin-bottom: 50px;
}
  
.sectionSpace-bigSmall{
    margin: 40px 0px;
}

.sectionSpace-xs{
    margin-bottom: 66px;
} 

.sectionSpace-mobile{
    margin-bottom: 50px;
}

.centerContainer{
    width: var(--center-content);
    margin: 0 auto;
}

.mediumTitle{
    font-size: 30px;
    font-weight: 600;
    line-height: 1.17;
    letter-spacing: 0.2px;
    color: #252525;
}

.marginTopTitle{
    margin-top: 100px;
}

.sectionBottom50{
    margin-bottom: 100px;
}

.sectionBottom50{
    margin-bottom: 30px;
}

@media (min-width: 960px) {
  
    .mediumTitle{
      font-size: 45px;
      line-height: 1.22;
      letter-spacing: -1.2px;
    }

    .sectionBottom50{
        margin-bottom: 50px;
    }

}